<template>
  <div class="payDialog">
    <el-dialog
      :title="'订单号：' + payInfo.order_no || '-'"
      :visible.sync="dialogVisible"
      width="1000px"
    >
      <div class="payInfo" v-loading="loading">
        <div>
          <div class="invoiceInfo">
            <div class="title">交易信息</div>
            <div class="tablePanel">
              <el-table :data="tableData" border style="width: 100%">
                <el-table-column prop="date" label="商品名称">
                  <template slot-scope="scope">
                    <div class="goodsContent">
                      <img
                        :src="scope.row.cover_url"
                        class="covelImg"
                        v-if="scope.row.cover_url"
                      />
                      <div>{{ scope.row.name | textFormat }}</div>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column prop="name" label="商品类型" width="180">
                  <template slot-scope="scope">
                    {{ scope.row.ctype == 1 ? "专栏" : "视频" }}
                  </template>
                </el-table-column>
                <el-table-column prop="address" label="商品价格">
                  <template slot-scope="scope">
                    
                    {{  scope.row.company_is_vip?scope.row.price_actual:scope.row.price_scribe }}
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <div class="tr total">
              <span
                >应付金额：<span class="dangerColor"
                  >￥{{ orderData.company_is_vip?orderData.price_actual:orderData.price_scribe | textFormat }}</span
                ></span
              >
            </div>
          </div>
          <div class="payType">
            <div class="title">支付方式</div>
            <div class="content clearfix">
              <div
                v-for="(item, index) in payList"
                :key="index"
                class="item fl cursor"
                :class="{ border: activeIndex == index }"
                @click="payChange(index, item)"
                v-if="item.isPay"
              >
                <div>
                  <img :src="item.icon" />
                  <span>{{ item.text }}</span>
                  <span v-if="item.payment == 5">: {{ item.balance }}</span>
                  <span v-if="item.payment == 6">: {{ item.balance }}</span>
                  <div v-show="activeIndex == index">
                    <div class="bottomRight"></div>
                    <i class="el-icon-check ckeck"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="payTips">
            温馨提示：请尽快完成付款，系统会自动取消
            <span><span v-if="minutes < 10">0</span>{{ minutes }}</span
            ><span>:<span v-if="seconds < 10">0</span>{{ seconds }}</span>
            分钟内尚未付款订单!
          </div>
        </div>
        <div class="isCountDown tc" v-if="isCountDown">
          <i class="el-icon-warning-outline"></i>
          <div class="tc">当前订单已失效，请重新认领</div>
        </div>
      </div>
      <div slot="footer" class="dialog-footer" v-if="!isCountDown">
        <div>
          <div class="actually">
            <span
              >实付：<span class="dangerColor">
                <span v-if="activeIndex != 4"
                  ><span v-if="activeIndex != 3">￥</span>
                  {{ orderData.company_is_vip?orderData.price_actual:orderData.price_scribe | textFormat }}
                  <span v-if="activeIndex == 3">积分</span>
                </span>
                <span v-if="activeIndex == 4">1</span>
              </span></span
            >
          </div>
          <!-- <span>
            <el-checkbox v-model="checked"></el-checkbox> 提交订单则表示您同意
            <span class="cursor">《xxxx用户服务协议》</span>
            、 <span class="cursor">《xxx售后政策》</span>等。</span
          > -->
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" :disabled="!checked" @click="payOrderCreate"
            >立即支付</el-button
          >
        </div>
      </div>
    </el-dialog>
    <el-dialog
      :visible.sync="idcodeVisible"
      width="240px"
      :close-on-click-modal="true"
    >
      <div class="idCodeDialog">
        <div id="idCode" style="margin: 10px"></div>
        <div class="tc" style="padding-bottom: 10px; width: 160px">
          扫描二维码
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    payType: {
      type: Number,
      default: 1, // 1-询盘 2-课程
    },
  },
  data() {
    return {
      dialogVisible: false,
      idcodeVisible: false,
      checked: true,
      loading: false,
      activeIndex: 1,
      timer: null,
      minutes: 0,
      seconds: 0,
      isCountDown: false,
      orderData: {},
      payInfo: {},
      tableData: [],
      payList: [
        {
          icon: "https://attached-file.oss-cn-shanghai.aliyuncs.com/aila3.0/3.0Resource/image/icon/alipay.png",
          text: "支付宝支付",
          payment: 2,
          isPay: false,
        },
        {
          icon: "https://attached-file.oss-cn-shanghai.aliyuncs.com/aila3.0/3.0Resource/image/icon/wechat.png",
          text: "微信支付",
          payment: 1,
          isPay: false,
        },
        {
          icon: "https://attached-file.oss-cn-shanghai.aliyuncs.com/aila3.0/3.0Resource/image/icon/paypal.png",
          text: "PayPal支付",
          payment: 3,
          isPay: false,
        },
        {
          icon: "https://attached-file.oss-cn-shanghai.aliyuncs.com/aila3.0/3.0Resource/image/icon/integral.png",
          text: "积分支付",
          payment: 5,
          isPay: false,
          balance: null,
        },
        {
          icon: "https://attached-file.oss-cn-shanghai.aliyuncs.com/aila3.0/3.0Resource/image/icon/branch.png",
          text: "条数支付",
          payment: 6,
          isPay: false,
          balance: null,
        },
      ],
    };
  },
  watch: {
    dialogVisible(val) {
      if (val == false) {
        clearInterval(this.timer);
        clearInterval(this.minuteTime);
        this.$emit("closeChange");
      }
    },
  },
  methods: {
    show(val) {
      console.log('***',val);
      this.dialogVisible = true;
      this.payInfo = val.order;
      this.payInit(val.info);
      this.tableData = [val.info];
    },
    // 支付初始化
    async payInit(value) {
      let result = JSON.parse(JSON.stringify(value));
      try {
        this.loading = true;
        // result.price_actual = (result.price_actual / 100).toFixed(2);
        // result.goods_price = (result.goods_price / 100).toFixed(2);
        // result.price_scribe = (result.price_scribe / 100).toFixed(2);
        let now_time = this.$moment(new Date()).unix();
        var latency = now_time - this.payInfo.create_time;
        var expire = this.payInfo.expire_time - this.payInfo.create_time;
        var time = expire - latency;
        var times = this.$moment.duration(time, "seconds");
        var min = times.minutes();
        var ss = times.seconds();
        this.minutes = min > 0 ? min : 0; //parseInt(result.data.pay_latency_time) / 60 || 0;
        this.seconds = ss > 0 ? ss : 0;
        if (latency > expire) {
          this.isCountDown = true;
        }
        this.orderData = result;
        this.countDown();
        this.payList = this.payListFind(result);
        this.loading = false;
      } catch (e) {
        console.log(e);
        this.loading = false;
      }
    },
    // 支付方式比对
    payListFind(result) {
      console.log(result);
      let list = JSON.parse(JSON.stringify(this.payList));
      let arr1 = [];
      list.forEach((item) => {
        let obj = item;
        let isPay = result.payment == item.payment;
        if (isPay) {
          obj.isPay = true;
          if (isPay.balance) obj.balance = isPay.balance;
        } else {
          obj.isPay = false;
        }
        arr1.push(obj);
      });
      return arr1;
    },
    // 订单关闭倒计时
    countDown() {
      this.minuteTime = setInterval(() => {
        if (this.seconds == 0 && this.minutes != 0) {
          this.seconds = 59;
          this.minutes = this.minutes - 1;
        } else if (this.minutes == 0 && this.seconds == 0) {
          this.seconds = 0;
          clearInterval(this.minuteTime);
          clearInterval(this.timer);
          this.idcodeVisible = false;
          this.isCountDown = true;
        } else {
          this.seconds = this.seconds - 1;
        }
      }, 1000);
    },
    payChange(index, item) {
      this.activeIndex = index;
    },
    // // 创建交易订单
    async payOrderCreate(item) {
      this.idcodeVisible = true;
      this.openIdCode(this.payInfo.code_url);
      this.timerOrderStatus();
      //   let value = this.payList[this.activeIndex];
      //   let obj = this.tableConfig.tableData[0];
      //   let params = {
      //     tenant_id: this.TENANT_ID,
      //     payment: value.payment,
      //     good_name: obj.goods_name,
      //     order_no: obj.order_no,
      //     amount_paid: obj.amount_paid * 100,
      //   };
      //   try {
      //     let result = await this.$store.dispatch(
      //       "transaction/payOrderCreate",
      //       params
      //     );
      //     if (result.success) {
      //       this.idcodeVisible = true;
      //       this.openIdCode(result.data);
      //       this.timerOrderStatus();
      //     } else {
      //     }
      //   } catch (e) {
      //     console.log(e);
      //   }
    },
    openIdCode(url) {
      this.$nextTick(() => {
        if (!this.qrcode) {
          this.qrcode = new QRCode("idCode", {
            text: url,
            width: 160,
            height: 160,
            colorDark: "#000000",
            colorLight: "#ffffff",
            correctLevel: QRCode.CorrectLevel.H,
          });
        } else {
          this.qrcode.clear();
          this.qrcode.makeCode(url);
        }
      });
    },
    // 定时器每5秒获取一下支付状态、
    timerOrderStatus() {
      this.timer = setInterval(() => {
        this.payOrderStatus();
      }, 5000);
      //  clearInterval(this.timer);
      //   this.timer = null;
    },
    // 获取订单支付状态
    async payOrderStatus() {
      let value = this.payList[this.activeIndex];
      let result = await this.$store.dispatch("API_company/payOrderStatus", {
        user_id: this.USER_INFO.id,
        related_id: this.orderData.id,
        goods_type: 1,
      });
      if (result.success) {
        if (result.data.status == 0) {
        } else {
          clearInterval(this.timer);
          this.timer = null;
          this.idcodeVisible = false;
          this.dialogVisible = false;
          this.$emit("paySuccessChange");
        }
      } else {
        clearInterval(this.timer);
        this.timer = null;
      }
    },
  },
  mounted() {},
};
</script>

<style scoped lang="less">
// @import "~web/styles/projectTheme.scss";

.invoiceInfo {
  .title {
    font-weight: bold;
    margin: 10px 0 20px;
  }
}
.payType {
  .title {
    font-weight: bold;
    margin: 30px 0 20px;
  }
  .content {
    .item {
      width: 176px;
      padding: 10px;
      margin-right: 20px;
      border: 1px solid #dddddd;
      position: relative;
      &:hover {
        border: 1px solid #e3954f;
      }
      img {
        width: 30px;
      }
      span {
        margin-left: 8px;
      }
      &:last-child {
        margin-right: 0;
      }
      .ckeck {
        position: absolute;
        bottom: 0px;
        right: 0px;
        color: #fff;
      }
    }
    .border {
      border: 1px solid #e3954f;
    }
  }
}
.payTips {
  margin: 40px 0;
  span {
    font-size: 18px;
    font-weight: bold;
  }
}
.total {
  padding: 12px 16px;
  border: 1px solid #ebeef5;
  margin-top: -1px;
  & > span {
    margin-left: 24px;
  }
}
.bottomRight {
  width: 0;
  height: 0;
  border-bottom: 24px solid #e3954f;
  border-left: 24px solid transparent;
  position: absolute;
  bottom: 0;
  right: 0;
}
.actually {
  margin-bottom: 16px;
}
.dangerColor {
  font-weight: bold;
}
.idCodeDialog {
  padding: 20px 0 20px 10px;
}
.payInfo {
  position: relative;
}
.isCountDown {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #fff;
  z-index: 10;
  padding-top: 100px;
  div {
    font-size: 20px;
  }
  i {
    font-size: 48px;
    color: #fd8612;
    margin-bottom: 24px;
  }
}
.covelImg {
  width: 70px;
  height: 40px;
  margin-right: 10px;
}
.goodsContent {
  display: flex;
  align-items: center;
  div {
    width: 70%;
  }
}
// .dialog-footer{
// }
</style>
<style lang="less" >
.payDialog {
  .el-dialog__footer {
    border-top: 1px solid #dddddd;
  }
}
</style>