<template>
  <div>
    <div class="search">
      <el-row :gutter="20">
        <el-col :span="3">
          <el-input
            v-model="queryParams.goods_name"
            placeholder="商品信息"
            size="small"
          ></el-input>
        </el-col>
        <!-- <el-col :span="3">
          <el-select
            size="small"
            v-model="queryParams.goods_type"
            placeholder="商品类型"
          >
             <el-option
              v-for="item in goodsType"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-col>
        <el-col :span="3">
          <el-select
            size="small"
            v-model="queryParams.payment"
            placeholder="支付方式"
          >
             <el-option
              v-for="item in payType"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-col> -->
        <el-col :span="3">
          <el-input
            size="small"
            v-model="queryParams.order_no"
            placeholder="业务订单号"
          ></el-input>
        </el-col>
        <!-- <el-col :span="3">
          <el-input
            size="small"
            v-model="queryParams.company_user_name"
            placeholder="姓名/公司名称"
          ></el-input>
        </el-col> -->
        <el-col :span="6">
          <el-date-picker
            size="small"
            class="search-time"
            v-model="queryParams.time"
            type="daterange"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          ></el-date-picker>
        </el-col>
        <el-col :span="12">
          <el-button size="small" type="default" class="fr" @click="reset"
            >重置</el-button
          >
          <el-button
            size="small"
            type="primary"
            class="fr"
            style="margin-right: 12px"
            @click="search"
            >查询</el-button
          >
        </el-col>
      </el-row>
    </div>
    <div class="tableBlock" v-loading="loading">
      <!-- <div class="columns-btn" v-if="hasColumnBtn">
        <el-button
          type="success"
          icon="el-icon-plus"
          @click="openDialog"
          v-if="hasAuth('cms.pbty.add')"
          >添加</el-button
        >
      </div> -->
      <el-table
        :data="tableData"
        :height="tableHeight"
        v-tableHeight="{ bottomOffset: 74 }"
        style="width: 100%"
        border
      >
        <el-table-column label="下单时间" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <span>{{ scope.row.created_time | secondFormat("LLL") }}</span>
          </template>
        </el-table-column>
        <el-table-column label="商品名称" :show-overflow-tooltip="true">
          <template slot-scope="scope">{{
            scope.row.goods_name | textFormat
          }}</template>
        </el-table-column>
        <el-table-column label="商品类型" :show-overflow-tooltip="true">
          <template slot-scope="scope">{{
            scope.row.goods_type == 1 ? "课程" : "询盘" | textFormat
          }}</template>
        </el-table-column>
        <!-- <el-table-column label="卖家" :show-overflow-tooltip="true">
          <template slot-scope="scope">{{
            scope.row.goods_user_name | textFormat
          }}</template>
        </el-table-column>
        <el-table-column label="卖家公司" :show-overflow-tooltip="true">
          <template slot-scope="scope">{{
            scope.row.goods_company_name | textFormat
          }}</template>
        </el-table-column> -->
        <el-table-column label="单价" :show-overflow-tooltip="true">
          <template slot-scope="scope">{{
            scope.row.amount_paid / 100
          }}</template>
        </el-table-column>
        <el-table-column label="支付方式" :show-overflow-tooltip="true">
          <template slot-scope="scope">{{
            scope.row.payment | paymentMethodFormat(LOCALE)
          }}</template>
        </el-table-column>
        <el-table-column label="支付金额" :show-overflow-tooltip="true">
          <template slot-scope="scope">{{
            (scope.row.amount_paid / 100) | textFormat
          }}</template>
        </el-table-column>
        <el-table-column label="业务订单号" :show-overflow-tooltip="true">
          <template slot-scope="scope">{{
            scope.row.order_no | textFormat
          }}</template>
        </el-table-column>
        <el-table-column label="订单状态" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <span v-if="scope.row.isLose == 1">已失效</span>
            <span v-else>{{
              scope.row.status | orderStatusFormat(LOCALE)
            }}</span>
          </template>
        </el-table-column>
        <el-table-column label="付款时间" :show-overflow-tooltip="true">
          <template slot-scope="scope">{{
            scope.row.pay_time | secondFormat("LLL")
          }}</template>
        </el-table-column>
        <el-table-column label="操作" width="120">
          <template slot-scope="scope">
            <el-button
              v-if="scope.row.isLose != 1 && scope.row.status == 0"
              type="text"
              size="small"
              @click="payCourse(scope.row)"
              >支付</el-button
            >
            <el-button type="text" size="small" @click="edit(scope.row)"
              >订单详情</el-button
            >
            <!-- <el-button
              type="text"
              size="small"
              @click="deleteData(scope.row)"
              >删除</el-button
            >-->
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="paginationBlock">
      <el-pagination
        layout="total, prev, pager, next"
        :total="total"
        :current-page.sync="currentPage"
        :page-size="limit"
      ></el-pagination>
    </div>
    <orderDetail ref="orderDetail"></orderDetail>
    <payDialog ref="payDialog" @paySuccessChange="paySuccess"></payDialog>
  </div>
</template>
<script>
import orderDetail from "~bac/components/order/orderDetail";
import payDialog from "~scb/components/common/payDialog";
import { priorFormat } from "@/basePlugins/filters";
// import { orderStatusFormat, paymentMethodFormat } from "@/filters";

// import { getOrderList } from "@/api/course";
export default {
  components: { orderDetail, payDialog },
  props: {
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      currentPage: 1,
      limit: 15,
      tableHeight: 100,
      hasColumnBtn: false, //当页面需要新增按钮时，置为true
      loading: false,
      goodsType: [
        {
          value: 1,
          label: "课程",
        },
        {
          value: 2,
          label: "询盘",
        },
      ],
      payType: [
        {
          value: 1,
          label: "微信支付",
        },
        {
          value: 2,
          label: "支付宝支付",
        },
        {
          value: 3,
          label: "paypal支付",
        },
        {
          value: 4,
          label: "线下支付",
        },
      ],
      queryParams: {
        name: "",
      },
      total: 0,
      tableData: [],
    };
  },
  watch: {
    currentPage() {
      this.getList();
    },
  },
  mounted() {
    this.getList();
  },
  methods: {
    reset() {
      let _params = Object.assign({}, this.queryParams);
      Object.keys(_params).forEach((item) => {
        _params[item] = null;
      });
      this.queryParams = _params;
    },
    search() {
      let obj = Object.assign({}, this.queryParams);
      if (this.queryParams.time) {
        let start_time = this.$moment(this.queryParams.time[0])
          .startOf("day")
          .unix();
        let end_time = this.$moment(this.queryParams.time[1])
          .endOf("day")
          .unix();
        obj.start_time = start_time;
        obj.end_time = end_time;
        delete obj.time;
      }
      this.currentPage = 1;
      this.getList(obj);
    },
    // 获取数据
    async getList(data) {
      this.loading = true;
      let params = {
          start: this.currentPage - 1,
          limit: this.limit,
          nopage: 0,
          source: this.PJSource,
          belongto: 3,
          user_id: this.USER_INFO.id,
          goods_type: 1,
        },
        _params;
      _params = Object.assign(params, data);
      let res = await this.$store.dispatch("baseConsole/getOrderList", _params);
      if (res.success) {
        let now_time = this.$moment(new Date()).unix();
        res.data.forEach((item) => {
          item.previewList = [];
          if (item.avatar) {
            item.previewList.push(item.avatar);
          }
          if (item.status == 0 && now_time > item.time_expire) {
            item.isLose = 1;
          }
        });
        this.tableData = res.data;
        this.total = res.count;
        this.loading = false;
      } else {
        this.loading = false;
      }
      this.loading = false;
      //this.total = res.total;
    },
    paySuccess() {
      this.getList();
    },
    async payCourse(item) {
      let info = this.USER_INFO;
      let values = this.dataChange(item);
      // let params = {
      //    goods_type: 1,
      //   belongto: 3,
      //   source: this.PJSource,
      //   goods_belongto: 3,
      //   user_id: info.id,
      //   user_name: priorFormat(info.name_en, info.name_zh, this.LOCALE),
      //   company_id: info.company_id,
      //   company_name: priorFormat(
      //     info.company_name_en,
      //     info.company_name_zh,
      //     this.LOCALE
      //   ),
      //   amount_total: item.amount_total,
      //   amount_paid: item.amount_paid,
      //   payment: 1,
      //   status: 0,
      //   related_id: item.related_id,
      //   goods_name: item.goods_name,
      //   goods_price: item.goods_price,
      //   order_id: item.order_id,
      //   order_no: item.order_no,
      // };
      try {
        let result = await this.$store.dispatch("API_company/payCourseOrder", {
          order_id: item.order_id,
        });
        if (result.success) {
          let obj = {
            info: values,
            order: result.data,
          };
          obj.info.payment = 1;
          this.$nextTick((e) => {
            this.$refs.payDialog.show(obj);
          });
        }
      } catch (e) {
        console.log(e);
      }
    },
    dataChange(value) {
      let obj = {};
      obj.price_scribe = value.amount_total
        ? (value.amount_total / 100).toFixed(2)
        : 0;
      obj.price_actual = value.amount_paid
        ? (value.amount_paid / 100).toFixed(2)
        : 0;
      obj.id = value.goods_id;
      obj.name = value.goods_name;
      return obj;
    },
    edit(scope) {
      this.$nextTick((e) => {
        this.$refs.orderDetail.show(scope, scope.isLose);
      });
    },
  },
};
</script>
<style scoped lang="less">
.uploadwrap {
  max-width: 850px;
  // display: flex;
  // justify-content: space-between;
  // flex-wrap: wrap;

  .meetingintroen,
  .meetingintrozh {
    width: 50%;
  }

  .pcimg,
  .pcimgen {
    height: 211px;

    .el-image {
      width: 497px;
      height: 200px;
      border: 1px solid #ccc;
    }

    .el-icon-plus {
      width: 497px;
      height: 200px;
      font-size: 50px;
      text-align: center;
      line-height: 200px;
      border: 1px solid #ccc;
      color: #999;
      cursor: pointer;
    }
  }
  .logoimg {
    height: 80px;
    .el-image {
      width: 72px;
      height: 66px;
      border: 1px solid #ccc;
    }
    .el-icon-plus {
      width: 72px;
      height: 66px;
      font-size: 30px;
      text-align: center;
      line-height: 66px;
      border: 1px solid #ccc;
      color: #999;
      cursor: pointer;
    }
  }
}
.companyAvatar {
  width: 120px;
  height: 120px;
  overflow: hidden;
}
.search {
  padding: 24px 12px;
}
</style>
